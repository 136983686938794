<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-08 17:45:40
-->
<template>
	<div
		class="index"
		v-loading="loading"
		:style="{
			height: pageHeight
		}"
	>
		<Parser v-if="inited"></Parser>
	</div>
</template>

<script>
import { getRenderData } from '@/apis/data/index';
import Parser from '@/components/parser/Index';
import { isJSONStr } from '@/utils/tools';
import { mapState } from 'vuex';

export default {
	name: 'Index',
	components: {
		Parser
	},
	props: {
    pageUUID: {
      type: String,
      required: false,
      default: ''
    }
  },
	data() {
		return {
			inited: false,
			loading: false,
			renderUUID: null
		};
	},
	computed: {
		...mapState(['targetGlobalTab']),
		// 根据当前点击的顶部菜单判断是否内容区全屏显示
		isFullScreen() {
			const FULLTABS = ['dataview'];
			return !this.targetGlobalTab || FULLTABS.includes(this.targetGlobalTab);
		},
		// 页面高度
		pageHeight() {
			if (this.isFullScreen) {
				return 'calc(100vh - 80px)'
			}
			if (this.pageUUID) {
				return 'calc(100vh - 140px)';
			}
			return 'calc(100vh - 130px)';
		}
	},
	created() {
		this.getViewData();
	},
	watch: {
		$route() {
			const renderUUID = this.$route.query.pageUUID
			if (renderUUID === this.renderUUID) return;
			this.reRender();
		}
	},
	methods: {
		/**
		 * @desc: 获取dataview配置数据
		 */
		getViewData() {
			this.loading = true;
			this.renderUUID = this.$route.query.pageUUID
			getRenderData({
				__method_name__: 'dataList',
				page_uuid: this.pageUUID || this.renderUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					const { page_data } = res?.data?.data?.[0] || {};
					const { componentData, editorConfig, editorType, statusConfig } = isJSONStr(page_data) ? JSON.parse(page_data) : page_data
					this.$store.commit('setConfigData', {
						componentData,
						editorConfig,
						editorType
					});
					if (statusConfig && Array.isArray(statusConfig) && statusConfig.length) {
						this.$store.commit('initPageCustomStatus', statusConfig);
					}
					this.inited = true;
				})
				.catch(() => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 重新渲染
		 * @param {*}
		 * @return {*}
		 */
		reRender() {
			// console.log('重新渲染');
			this.$store.commit('clearPageCustomStatus');
			this.inited = false;
			this.getViewData();
		}
	},
	beforeDestroy() {
		this.$store.commit('clearPageCustomStatus');
	}
};
</script>

<style lang="less" scoped>
.index {
	width: 100%;
	// height: calc(100vh - 130px);
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	padding: 10px;
}
</style>